export default [
    //BEGIN auth
    {
        path: "/home",
        name: "default",
        component: () => import("./view/Landing.vue"),
        meta: {
            title: "Home",
            authRequired: false,
            isLanding: true,
        }
    },
    {
        path: "/",
        redirect: { name: "default" },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: { name: "default" },
    },
]
