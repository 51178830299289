import { toast } from 'vue3-toastify';
import authConstants from "@/packages/auth/authConstants";
import call from "@solutech/auth";
import AuthService from "@solutech/auth/src/access-control/AuthService";
export default {
    namespaced: true,
    state: {
        submitting:false,
        message:""
    },
    getters: {
        AuthGetter: state =>setup => state[setup]
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data;
        }
    },
    actions: {
        /**
         * Login action
         * @param commit
         * @param payload
         */
        login: ({ commit }, payload) => {
            commit("MUTATE", { state: "submitting", data: true });
            call("post", authConstants.LOGIN, payload)
                .then(response => {
                    commit("MUTATE", { state: "submitting", data: false });
                    if (response.data.success) {
                        AuthService.login(response.data.data.access_token,response.data.data.account);
                        toast.success(response?.data?.message || "You have successfully logged in");
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    commit("MUTATE", { state: "submitting", data: false });
                    toast.error(error.response.data.message);
                })
        },
        /**
         * Logout action
         */
        logout: ({commit}) =>{
            commit("MUTATE", { state: "submitting", data: true });
            call("post", "/auth/sign-out")
                .then(response => {
                    if (response.success) {
                        AuthService.logout();
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch(error => {
                    commit("MUTATE", { state: "submitting", data: false });
                    toast.error(error.response.data.message);
                })
        },
        /**
         * Register action
         */
        register: ({commit},payload) =>{
            commit("MUTATE", { state: "submitting", data: true });
            call("post", "/auth/sign-up",payload)
                .then(response => {
                    if (response.success) {
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch(error => {
                    commit("MUTATE", { state: "submitting", data: false });
                    toast.error(error.response.data.message);
                })
        },
        /**
         * Forgot password action
         */
        sendResetLink: ({commit},payload) =>{
            commit("MUTATE", { state: "submitting", data: true });
            call("post", "password",payload)
                .then(response => {
                    commit("MUTATE", { state: "submitting", data: false });
                    if (response.data.status) {
                        toast.success(response.data.message);
                        commit("MUTATE", { state: "message", data: response.data.message });
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    commit("MUTATE", { state: "submitting", data: false });
                    toast.error(error.response.data.message);
                })
        },
        /**
         * Reset password action
         */
        resetPassword: ({commit},payload) =>{
            commit("MUTATE", { state: "submitting", data: true });
            call("post", "reset",payload)
                .then(response => {
                    commit("MUTATE", { state: "submitting", data: false });
                    if (response.data.status===201) {
                        toast.success(response.data.message);
                        //push to login page
                        setTimeout(()=>{
                            window.location.href="/auth/login"
                        },2000)
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    commit("MUTATE", { state: "submitting", data: false });
                    toast.error(error.response.data.message);
                })
        }
    }
}
