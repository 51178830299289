import _ from "lodash";
import dashboardConstants from "@/packages/dashboard/dashboardConstants";
import call from "@solutech/auth";
export default {
    namespaced: true,
    state: {
        links:[
            {
                id: "main_menu",
                permission: "main_menu",
                disabled: false,
                title: "Dashboard",
                order: 1,
                iconType:"remix",
                icon: "ri-dashboard-line",
                menus:[
                    {
                        id: "dashboard_menu",
                        permission: "dashboard_menu",
                        disabled: false,
                        name: "Statistics",
                        order: 1,
                        iconType:"remix",
                        icon: "ri-dashboard-2-line",
                        link: "/dashboard/statistics",
                        subMenu: [],
                    }
                ]
            },
        ],
        loading: false,
        summary:{}
    },
    getters:{
        DashboardGetter: state=>setup=>state[setup],
        //order by order
        DashboardGetterLinks: (state) => {
            return _.orderBy(state.links, 'order');
        },
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data
        },
        MUTATE_LINKS: (state, payload) => {
            const titles = state.links.map(link => link.title)
            payload.forEach(item => {
                const index = titles.indexOf(item.title)
                if (index === -1) {
                    state.links.push(item)
                } else {
                    const menus = item.menus || []
                    state.links[index].menus.push(...menus)
                }
            })
        }
    },
    actions: {
        setLinks: ({commit}, payload) => {
            commit('MUTATE', {
                state: 'sidebarLinks',
                data: payload
            })
        },
        /**
         * Get SUMMARY
         * @param commit
         */
        getSummary: ({commit}) => {
            commit("MUTATE", {state: "loading", data: true})
            call('get', dashboardConstants.SUMMARY)
                .then(res => {
                    commit("MUTATE", {state: "loading", data: false})
                    commit("MUTATE", {
                        state: "summary",
                        data: res.data,
                    });
                })
                .catch(() => {
                    commit("MUTATE", {state: "loading", data: false})
                })
        }
    }
}
