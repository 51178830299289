import store from "@/state/store";
import AuthService from "scaling-garbanzo/src/access-control/AuthService";

export default [
    //BEGIN auth
    {
        path: "/auth/login",
        name: "login",
        component: () => import("../auth/components/account/login.vue"),
        meta: {
            title: "Login",
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/auth/register",
        name: "Register",
        component: () => import("../auth/components/account/register.vue"),
        meta: {
            title: "Register",
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (AuthService.check()) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/auth/forgot-password",
        name: "Forgot password",
        component: () => import("../auth/components/account/forgot-password.vue"),
        meta: {
            authRequired: false,
        }
    },
    {
        path: "/auth/reset-password/:code",
        name: "Reset password",
        component: () => import("./components/account/reset/cover.vue"),
        meta: {
            title: "Reset password",
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },

    {
        path: "/auth/logout",
        name: "logout",
        component: () => import("../auth/components/logout/basic")
    },
    {
        path: "/auth/lockscreen-basic",
        name: "logout",
        meta: {
            title: "Logout", authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("../auth/components/lockscreen/cover.vue")
    },
    //END Auth--
]
