import AuthStore from "@/packages/auth/authStore";
import AuthRoutes from "@/packages/auth/authRoutes";

export default {
    install(app,{router,store}) {
        if (store) {
            store.registerModule("Auth",AuthStore)
        } if (router) {
            AuthRoutes.forEach(route => {
                router.addRoute(route);
            })
        }
    }
}
